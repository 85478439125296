<template>
  <div class="vipCenter-bg">
    <div class="yx-wid-12 vipCenter">
      <div class="user-left" v-if="userInfo">
        <!-- menus -->
        <div class="user-nav">
          <div class="userInfo">
            <div class="photo" @click="setPhoto">
              <img v-lazy="userInfo.photoUrl" />
            </div>
            <div class="name">
              <span class="text">{{ userInfo.userName }}</span>
              <img
                v-if="userInfo && userInfo.levelId >= 0"
                :src="
                  require('@/assets/images/mine/level/vip' +
                    userInfo.levelId +
                    '.webp')
                "
              />
            </div>
            <p class="time" v-if="userInfo && baseInfo && baseInfo[2]">
              {{ $t("mine.index.252217-0") }}{{ baseInfo[2].configValue
              }}{{ userInfo.joinDays }}{{ $t("mine.index.252217-1") }}
            </p>
          </div>
          <ul class="user-box-ul">
            <li
              :class="{ hover: isHover === '/finance/deposit' }"
              @click="judgeMoblieOrPayPwd('/finance/deposit')"
            >
              <span class="icon sjf-wallet"></span>
              <p>{{ $t("mine.index.252217-2") }}</p>
            </li>
            <li
              :class="{ hover: isHover === '/finance/transfer' }"
              @click="$router.push('/finance/transfer')"
            >
              <span class="icon sjf-transfer"> </span>
              <p>{{ $t("mine.index.252217-3") }}</p>
            </li>
            <li
              :class="{ hover: isHover === '/finance/withdrawal' }"
              @click="judgeMoblieOrPayPwd('/finance/withdrawal')"
            >
              <span class="icon sjf-withdraw"> </span>
              <p>{{ $t("mine.index.252217-4") }}</p>
            </li>
          </ul>
          <ul class="user-nav-ul">
            <li
              v-for="(item, index) in menus"
              :key="index"
              :class="{ hover: item.aid === menuInd }"
              @click="tabMenu(item)"
            >
              <span class="icon" :class="item.icon"></span>
              <span class="text">{{ item.name }}</span>
              <span class="message" v-if="item.aid === 8 && message > 0">
                {{ message }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <router-view></router-view>
      <div class="user-right" v-if="isShowRight">
        <!-- 个人资料 -->
        <template
          v-if="['/mine/userInfo', '/finance/banks'].includes($route.path)"
        >
          <!-- 投注记录 -->
          <div class="user-jv">
            <div class="user-right-h3">
              <span class="text">{{ $t("mine.index.252217-5") }}</span>
              <span class="more" @click="$router.push('/record/betRecord')">
                {{ $t("mine.index.252217-6") }}<i></i>
              </span>
            </div>
            <ul class="pay-jl-list" v-if="recordList && recordList.length">
              <li v-for="(item, index) in recordList" :key="index">
                <span class="name">{{ item.gameName }}</span>
                <p class="row">
                  <span class="left" v-if="item.validBetAmount">{{
                    item.validBetAmount.toFixed(2)
                  }}</span>
                  <span class="right">{{ getState(item.status) }}</span>
                </p>
              </li>
            </ul>
            <div v-else class="user-jv-node">
              {{ $t("mine.index.252217-7") }}
            </div>
          </div>
          <!-- 卡 -->
          <div
            class="user-jv user-cark"
            v-if="['/mine/userInfo'].includes($route.path)"
          >
            <div class="cark-title">
              <ul>
                <li
                  v-for="(item, index) in bankListMenu"
                  :key="index"
                  :class="{ hover: carkInd === index }"
                  @click="tabBankList(index)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="bankList-right">
              {{
                carkInd === 1
                  ? $t("mine.index.252217-9")
                  : $t("mine.index.252217-8")
              }}
              <span
                class="text-blue"
                @click="$router.push(bankListMenu[carkInd].moreLink)"
                >{{ $t("mine.index.252217-6") }}</span
              >
            </div>
            <div class="card-list" v-if="bankList && bankList.length">
              <ul>
                <li
                  v-for="(item, index) in bankList"
                  :key="index"
                  :class="{ usdt: carkInd === 1 }"
                >
                  <img :src="item.iconUrl" class="sec-img" />
                  <div class="sec">
                    <span class="sec-title">{{ item.bankName }}</span>
                    <p class="sec-text">{{ item.cardType }}</p>
                  </div>
                  <div class="sec-bankNo">
                    {{ accountShow(item.bankcardNo) }}
                  </div>
                </li>
              </ul>
            </div>
            <div class="user-jv-node" v-else>
              {{ $t("mine.index.252217-10")
              }}<span @click="$router.push(bankListMenu[carkInd].addLink)">{{
                $t("mine.index.252217-11")
              }}</span>
            </div>
          </div>
        </template>
        <!-- 存款 -->
        <template v-if="['/finance/deposit'].includes($route.path)">
          <!-- 存款记录 -->
          <div class="user-jv">
            <div class="user-right-h3">
              <span class="text">{{ $t("mine.index.252217-12") }}</span>
              <span class="more" @click="$router.push('/record/transRecord')"
                >{{ $t("mine.index.252217-6") }}<i></i
              ></span>
            </div>
            <ul class="pay-jl-list" v-if="ckList && ckList.length">
              <li v-for="(item, index) in ckList" :key="index">
                <span class="name">{{ item.rechargeTypeName }}</span>
                <p class="row">
                  <span class="left" v-if="item.rechargeMoney">{{
                    item.rechargeMoney.toFixed(2)
                  }}</span>
                  <span class="right">{{ item.statusName }}</span>
                </p>
              </li>
            </ul>
            <div v-else class="user-jv-node">
              {{ $t("mine.index.252217-13") }}
            </div>
          </div>
        </template>
        <template v-if="['/finance/deposit'].includes($route.path)">
          <!--  优惠活动 -->
          <div class="user-jv user-cark">
            <div class="user-right-h3">
              <span class="text">{{ $t("mine.index.252217-14") }}</span>
              <span class="more" @click="$router.push('/activity/discount')"
                >{{ $t("mine.index.252217-6") }}<i></i
              ></span>
            </div>
            <div class="discount-list">
              <ul v-if="activityList">
                <li
                  v-for="(item, index) in activityList"
                  :key="index"
                  @click="
                    $router.push('/activity/activityLandPage?type=' + item.id)
                  "
                >
                  <img :src="item.listImgUrl" />
                </li>
              </ul>
              <div v-else class="user-jv-node">
                {{ $t("mine.index.252217-15") }}
              </div>
            </div>
          </div>
        </template>
        <!-- {{ $t('mine.index.252217-3') }} -->
        <template v-if="['/finance/transfer'].includes($route.fullPath)">
          <!-- {{ $t('mine.index.252217-16') }} -->
          <div class="user-jv">
            <div class="user-right-h3">
              <span class="text">{{ $t("mine.index.252217-16") }} </span>
              <span
                class="more"
                @click="$router.push('/record/transRecord?type=transfer')"
                >{{ $t("mine.index.252217-6") }}<i></i
              ></span>
            </div>
            <ul class="pay-jl-list" v-if="ckList && ckList.length">
              <li v-for="(item, index) in ckList" :key="index">
                <span class="name">
                  {{ convertType(item.convertType) }}
                  <!-- {{ $t('mine.index.252217-2') }} <img src="@/assets/images/mine/icon55.png" />{{ $t('mine.index.252217-2') }} -->
                </span>
                <p class="row">
                  <span class="left" v-if="item.convertMoney">{{
                    item.convertMoney.toFixed(2)
                  }}</span>
                  <span class="right">{{ item.statusName }}</span>
                </p>
              </li>
            </ul>
            <div v-else class="user-jv-node">
              {{ $t("mine.index.252217-17") }}
            </div>
          </div>
          <!--  {{ $t('mine.index.252217-14') }} -->
          <div class="user-jv user-cark">
            <div class="user-right-h3">
              <span class="text">{{ $t("mine.index.252217-18") }}</span>
              <span class="more" @click="openHelp(0)"
                >{{ $t("mine.index.252217-6") }}<i></i
              ></span>
            </div>
            <div class="help-list">
              <ul>
                <li @click="openHelp(30)">{{ $t("mine.index.252217-19") }}</li>
                <li @click="openHelp(240)">{{ $t("mine.index.252217-20") }}</li>
                <li @click="openHelp(380)">{{ $t("mine.index.252217-22") }}</li>
                <li @click="openHelp(380)">{{ $t("mine.index.252217-23") }}</li>
              </ul>
            </div>
          </div>
        </template>
        <!-- {{ $t('mine.index.252217-4') }} -->
        <template v-if="['/finance/withdrawal'].includes($route.path)">
          <!-- {{ $t('mine.index.252217-24') }} -->
          <div class="user-jv">
            <div class="user-right-h3">
              <span class="text">{{ $t("mine.index.252217-24") }} </span>
              <span
                class="more"
                @click="$router.push('/record/transRecord?type=withdraw')"
                >{{ $t("mine.index.252217-6") }}<i></i
              ></span>
            </div>
            <ul class="pay-jl-list" v-if="ckList && ckList.length">
              <li v-for="(item, index) in ckList" :key="index">
                <span class="name">
                  {{ item.withdrawMethodName }}
                </span>
                <p class="row">
                  <span class="left" v-if="item.withdrawAmount">{{
                    item.withdrawAmount.toFixed(2)
                  }}</span>
                  <span class="right">{{ item.statusName }}</span>
                </p>
              </li>
            </ul>
            <div v-else class="user-jv-node">
              {{ $t("mine.index.252217-25") }}
            </div>
          </div>
        </template>

        <template
          v-if="['/finance/withdrawal', '/finance/banks'].includes($route.path)"
        >
          <!--  {{ $t('mine.index.252217-14') }} -->
          <div class="user-jv user-cark">
            <div class="user-right-h3">
              <span class="text">{{ $t("mine.index.252217-18") }}</span>
              <span class="more" @click="openHelp(0)"
                >{{ $t("mine.index.252217-6") }}<i></i
              ></span>
            </div>
            <div class="help-list">
              <ul>
                <li @click="openHelp(30)">{{ $t("mine.index.477564-0") }}</li>
                <li @click="openHelp(160)">{{ $t("mine.index.477564-1") }}</li>
                <li @click="openHelp(240)">{{ $t("mine.index.477564-2") }}</li>
                <li @click="openHelp(400)">{{ $t("mine.index.477564-3") }}</li>
                <li @click="openHelp(500)">{{ $t("mine.index.477564-4") }}</li>
                <li @click="openHelp(590)">{{ $t("mine.index.477564-5") }}</li>
                <li @click="openHelp(670)">{{ $t("mine.index.477564-6") }}</li>
                <li @click="openHelp(800)">{{ $t("mine.index.477564-7") }}</li>
                <li @click="openHelp(900)">{{ $t("mine.index.477564-8") }}</li>
                <li @click="openHelp(1000)">{{ $t("mine.index.477564-9") }}</li>
                <li @click="openHelp(1000)">
                  {{ $t("mine.index.477564-10") }}
                </li>
                <li @click="openHelp(1000)">
                  {{ $t("mine.index.477564-11") }}
                </li>
              </ul>
            </div>
          </div>
        </template>
        <!-- {{ $t('mine.index.252217-43') }} -->
        <template
          v-if="
            [
              '/finance/wallet',
              '/addBankCard',
              '/virtualCoinCard',
              '/ebpayAccount',
            ].includes($route.path)
          "
        >
          <!-- {{ $t('mine.index.252217-5') }} -->
          <div class="user-jv">
            <div class="user-right-h3">
              <span class="text">{{ $t("mine.index.252217-5") }}</span>
              <span class="more" @click="$router.push('/record/betRecord')"
                >{{ $t("mine.index.252217-6") }}<i></i
              ></span>
            </div>
            <ul class="pay-jl-list" v-if="recordList && recordList.length">
              <li v-for="(item, index) in recordList" :key="index">
                <span class="name">{{ item.gameName }}</span>
                <p class="row">
                  <span class="left" v-if="item.validBetAmount">{{
                    item.validBetAmount.toFixed(2)
                  }}</span>
                  <span class="right">{{ getState(item.status) }}</span>
                </p>
              </li>
            </ul>
            <div v-else class="user-jv-node">
              {{ $t("mine.index.252217-7") }}
            </div>
          </div>
          <!--  {{ $t('mine.index.252217-14') }} -->
          <div class="user-jv user-cark">
            <div class="user-right-h3">
              <span class="text">{{ $t("mine.index.252217-14") }}</span>
              <span class="more" @click="$router.push('/activity/discount')"
                >{{ $t("mine.index.252217-6") }}<i></i
              ></span>
            </div>
            <div class="discount-list">
              <ul v-if="activityList">
                <li
                  v-for="(item, index) in activityList"
                  :key="index"
                  @click="
                    $router.push('/activity/activityLandPage?type=' + item.id)
                  "
                >
                  <img :src="item.listImgUrl" />
                </li>
              </ul>
              <div v-else class="user-jv-node">
                {{ $t("mine.index.252217-15") }}
              </div>
            </div>
          </div>
        </template>

        <!-- {{ $t('mine.index.252217-40') }} -->
        <template v-if="['/feedback'].includes($route.fullPath)">
          <!-- {{ $t('mine.index.252217-5') }} -->
          <div class="user-jv">
            <div class="user-right-h3">
              <span class="text">{{ $t("mine.index.252217-37") }}</span>
              <span class="more" @click="$router.push('/feedback/list')"
                >{{ $t("mine.index.252217-6") }}<i></i
              ></span>
            </div>
            <div
              class="user-jv-list"
              v-if="facebookList && facebookList.length"
            >
              <ul>
                <li
                  v-for="(item, index) in facebookList"
                  :key="index"
                  @click="openFacebook(item)"
                >
                  <span>{{ typeName(item.opinionType) }}</span>
                  <p>
                    {{ item.problemDescription }}
                  </p>
                </li>
              </ul>
            </div>

            <div class="user-jv-node" v-else>
              {{ $t("mine.index.252217-38") }}
            </div>
          </div>
        </template>
      </div>
      <SetPhoto
        v-if="isExitPhoto && userInfo && userInfo.photoUrl"
        :visible.sync="isExitPhoto"
        :title="$t('mine.index.252217-39')"
      />
      <helpCenter v-if="isHelp" :visible.sync="isHelp" :scrollTop="helpTop" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getTime } from "@/mixins/getTime";
import SetPhoto from "@/components/SetPhoto.vue";
import helpCenter from "@/components/TransferHelp.vue";
export default {
  components: { SetPhoto, helpCenter },
  data() {
    return {
      isHelp: false,
      helpTop: 0,
      menuInd: undefined,
      menus: [
        {
          name: this.$t("mine.index.252217-40"),
          link: "/mine/userInfo",
          aid: 1,
          icon: "sjf-user-id",
        },
        {
          name: this.$t("mine.index.252217-41"),
          link: "/mine/vip",
          aid: 2,
          icon: "sjf-v-crown",
        },
        {
          name: this.$t("mine.index.252217-42"),
          link: "/record/welfare",
          aid: 3,
          icon: "sjf-gift",
        },
        {
          name: "取款卡",
          link: "/finance/banks",
          aid: 10,
          icon: "sjf-credit-card",
        },
        {
          name: this.$t("mine.index.252217-43"),
          link: "/finance/wallet",
          aid: 4,
          icon: "sjf-money",
        },
        {
          name: this.$t("mine.index.252217-44"),
          link: "/record/transRecord",
          aid: 5,
          icon: "sjf-horizontal",
        },
        {
          name: this.$t("mine.index.252217-5"),
          link: "/record/betRecord",
          aid: 6,
          icon: "sjf-list",
        },
        {
          name: this.$t("mine.index.252217-45"),
          link: "/record/giftRecord",
          aid: 7,
          icon: "sjf-clipboard",
        },
        {
          name: this.$t("mine.index.252217-46"),
          link: "/mail",
          aid: 8,
          icon: "sjf-envelope",
        },
        {
          name: this.$t("mine.index.252217-47"),
          link: "/feedback",
          aid: 9,
          icon: "sjf-note-and-pen",
        },
        {
          name: '理财中心',
          link: "/center",
          aid: 11,
          icon: "sjf-money",
        },
      ],
      isExitPhoto: false,
      carkInd: 0,
      isShowRight: false,
      isHover: "",
      recordList: null, //投注列表
      // 卡片参数
      bankListMenu: [
        {
          name: this.$t("mine.index.252217-8"),
          type: 1,
          moreLink: "/finance/wallet",
          addLink: "/addBankCard",
        },
        {
          name: this.$t("mine.index.252217-9"),
          type: 2,
          moreLink: "/virtualCoinCard",
          addLink: "/virtualCoinCard",
        },
      ],
      bankList: null,
      // 存款参数
      ckList: null,
      // 反馈列表
      facebookList: null,
      // 活动列表
      activityList: null,
      userBankCardList: null,
    };
  },
  watch: {
    $route(val) {
      if (val.meta.isShow) {
        this.isShowRight = true;
      } else {
        this.isShowRight = false;
      }

      this.infoLeftVal();
      this.initPost();
    },
    isLogin(val) {
      if (val) {
        this.getUserInfo();
      }
    },
  },
  computed: {
    ...mapState(["userInfo", "message"]),
  },
  mounted() {
    this.infoRoute();
    this.infoLeftVal();
    this.getUnReadMessageCount();
    this.initPost();
    this.queryUserBankCardList();
    console.log(this.isShowRight);
  },
  methods: {
    queryUserBankCardList() {
      this.$Api.queryUserBankCardList().then((res) => {
        if (res) {
          this.userBankCardList = res.userBankCardList;
        }
      });
    },
    openHelp(val) {
      this.isHelp = true;
      document.body.style = "overflow:hidden";
      this.helpTop = val;
    },
    // 个人资料
    getUserInfo() {
      this.$Api.getUserInfo().then((res) => {
        if (res) {
          this.$store.dispatch("setUserInfoAc", res.userInfo);
        }
      });
    },
    // 设置头像
    setPhoto() {
      this.isExitPhoto = true;
    },
    // 切换银行卡
    tabBankList(index) {
      this.carkInd = index;
      if (index === 0) {
        this.cardPost();
      } else {
        this.cardPostUSDT();
      }
    },
    // 初始化调对应接口
    initPost() {
      const url = this.$route.path;
      // 个人资料
      if (["/mine/userInfo"].includes(url)) {
        this.tqPost(); //投注接口
        this.cardPost(); //银行卡接口
      }
      if (["/finance/banks", "/finance/wallet"].includes(url)) {
        this.tqPost(); //投注接口
      }
      if (url === "/finance/deposit") {
        this.ckPost(); //存款接口
      }
      if (url === "/finance/transfer") {
        this.ckPost("transfer"); //转账
      }
      if (url === "/finance/withdrawal") {
        this.ckPost("withdraw"); //取款
      }
      if (url === "/feedback") {
        this.getFeedbackList();
      }
      if (["/finance/deposit", "/finance/wallet"].includes(url)) {
        this.getActivityList();
      }
    },
    // 活动列表
    getActivityList() {
      const params = {
        activityClassId: "",
        device: 1,
        pageIndex: 1,
        pageSize: 5,
      };
      this.$Api.getActivityList(params).then((res) => {
        if (res) {
          this.activityList = res.activityList.records;
        }
      });
    },
    // 反馈列表
    getFeedbackList() {
      const params = {
        pageIndex: 1,
        pageSize: 20,
      };
      this.$Api.getFeedbackList(params).then((res) => {
        if (res) {
          this.facebookList = res.page.list;
        }
      });
    },
    jumpCenter(){
      window.open(this.baseInfo[57].configValue, "_blank");
    },
    openFacebook(val) {
      this.$router.push("/feedback/detail?order=" + val.id);
    },
    // 存款、转账、取款接口
    ckPost(val) {
      const params = {
        transType: val ? val : "recharge",
        startTime: getTime(),
        endTime: getTime(),
        pageIndex: 1,
        pageSize: 5,
      };
      this.$Api.getUserTransInfo(params).then((res) => {
        if (res) {
          this.ckList = res.page.records;
        }
      });
    },
    // 卡片列表
    cardPost() {
      this.$Api.queryUserBankCardList().then((res) => {
        if (res) {
          res.userBankCardList.forEach((item) => {
            item.cardType =
              item.cardType === 1
                ? this.$t("mine.index.252217-48")
                : this.$t("mine.index.252217-49");
          });
          this.bankList = res.userBankCardList;
          if (res.userBankCardList.length) {
            this.$store.dispatch("setIsBankLengthAc", true);
          }
        }
      });
    },
    cardPostUSDT() {
      this.$Api.queryUserBlockchainList().then((res) => {
        if (res) {
          res.userBlockchainList.forEach((item) => {
            item.iconUrl = require("@/assets/images/pay/xnt.png");
            item.bankName = item.name;
            item.bankcardNo = item.address;
            item.cardType = item.networkType;
            item.class = "usdt";
          });
          this.bankList = res.userBlockchainList;
        }
      });
    },
    // 投注接口
    tqPost() {
      const params = {
        platType: "",
        startTime: getTime(),
        endTime: getTime(),
        pageIndex: 1,
        pageSize: 5,
      };
      this.$Api.getBetRecord(params).then((res) => {
        if (res) {
          this.recordList = res.page.records;
        }
      });
    },
    // 投注状态
    getState(e) {
      const arr = [
        this.$t("mine.index.252217-50"),
        this.$t("mine.index.252217-51"),
        this.$t("mine.index.252217-52"),
      ];
      return arr[e];
    },
    // message
    getUnReadMessageCount() {
      this.$Api.getUnReadMessageCount().then((res) => {
        if (res) {
          const message =
            res.data.totalCount > 99 ? "99+" : res.data.totalCount;
          this.$store.dispatch("setMessageAc", message);
        }
      });
    },
    infoLeftVal() {
      if (
        [
          "/finance/deposit",
          "/finance/transfer",
          "/finance/withdrawal",
        ].includes(this.$route.fullPath)
      ) {
        this.isHover = this.$route.fullPath;
        this.menuInd = undefined;
      } else {
        this.isHover = "";
      }
      this.menus.map((item) => {
        if ("/mine/details" === this.$route.fullPath) {
          this.menuInd = 2;
          return;
        }
        if (item.link === this.$route.fullPath) {
          this.menuInd = item.aid;
        }
      });
    },
    infoRoute() {
      if (this.$route.meta.isShow) {
        this.isShowRight = true;
      } else {
        this.isShowRight = false;
      }
    },
    tabMenu(item) {
      if (item.aid === 10) {
        this.judgeMoblieOrPayPwd(item.link);
        return;
      }
      if (item.aid === 11) {
        this.jumpCenter();
        return;
      }
      this.menuInd = item.aid;
      this.$router.push(item.link);
    },
    convertType(val) {
      let textVal = "";
      switch (val) {
        case 1:
          textVal = this.$t("mine.index.252217-53");
          break;
        case 2:
          textVal = this.$t("mine.index.252217-54");
          break;
        case 3:
          textVal = this.$t("mine.index.252217-55");
          break;
        case 4:
          textVal = this.$t("mine.index.252217-56");
          break;
        case 5:
          textVal = this.$t("mine.index.252217-57");
          break;
        case 6:
          textVal = this.$t("mine.index.252217-58");
          break;
      }
      return textVal;
    },
    typeName(val) {
      const options = [
        {
          value: 1,
          label: this.$t("mine.index.252217-59"),
        },
        {
          value: 2,
          label: this.$t("mine.index.252217-60"),
        },
        {
          value: 3,
          label: this.$t("mine.index.252217-61"),
        },
        {
          value: 4,
          label: this.$t("mine.index.252217-62"),
        },
        {
          value: 5,
          label: this.$t("mine.index.252217-63"),
        },
        {
          value: 6,
          label: this.$t("mine.index.252217-64"),
        },
        {
          value: 7,
          label: this.$t("mine.index.252217-65"),
        },
        {
          value: 8,
          label: this.$t("mine.index.252217-66"),
        },
        {
          value: 9,
          label: this.$t("mine.index.252217-67"),
        },
      ];
      if (val) {
        return options.find((item) => item.value === val).label;
      } else {
        return "null";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vipCenter {
  display: flex;
  padding-top: 30px;
  width: 1200px;
  gap: 16px;

  &-bg {
    background: var(--bg-gray-10);
    background-size: cover;
    padding-bottom: 30px;
  }
}

.user-left {
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(152, 161, 182, 0.04);
  width: 220px;
  height: auto;
  flex: none;
  display: flex;
  flex-direction: column;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  .photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    // border: 4px solid white;

    &:hover {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url("@/assets/images/xj.png") no-repeat rgba(0, 0, 0, 0.3)
          center center;
        background-size: 24px auto;
        border-radius: 50%;
      }
    }

    img {
      width: 100%;
      height: 100%;
      background-color: #35376d59;
      border-radius: 100%;
    }
  }

  .name {
    display: flex;
    align-items: center;
    margin-top: 12px;

    .text {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: var(--color-black);
      font-weight: 400;
      font-family: DIN, PingFangSC-Regular, sans-serif !important;
    }
    img {
      height: 20px;
    }
  }

  .time {
    height: 17px;
    line-height: 17px;
    font-size: 13px;
    color: var(--color-gray);
    margin-top: 4px;
  }
}

.user-nav {
  padding: 10px 20px;
  flex: 1;
  background: white;
  border-radius: 12px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);

  .user-box-ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 20px 0;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &:hover,
      &.hover {
        color: var(--color-theme);
      }

      .icon {
        position: relative;
        font-size: 22px;
      }
    }
  }

  .user-nav-ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0;
    li {
      position: relative;
      overflow: hidden;
      background: transparent;
      border-left: 3px solid transparent;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: var(--color-gray1);
      height: 48px;
      font-size: 14px;

      &.hover,
      &:hover {
        background: #f4faff;
        border-radius: 12px;
        color: var(--color-theme);
      }

      .icon {
        margin: 0 10px 0 16px;
        font-size: 24px;
      }
      .text {
        font-weight: 500;
      }
    }
  }
}

.account-dj {
  color: white;
  font-size: 12px;
  margin-top: 16px;
}

.account-state {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 15%;
  margin-top: 6px;

  span {
    width: 22px;
    height: 22px;
    opacity: 0.5;

    &.hover {
      opacity: 1;
    }

    img {
      width: 100%;
    }
  }
}

.message {
  background: red;
  color: white;
  padding: 2px 7px;
  font-size: 12px;
  margin-left: 6px;
  line-height: 1;
  border-radius: 8px;
}
.user-right {
  display: flex;
  flex-direction: column;
  flex: none;
  z-index: 1;
  width: 220px;
  gap: 16px;
}

.discount-list {
  margin-top: 12px;

  li {
    width: 100%;
    height: 90px;
    margin-bottom: 12px;
    cursor: pointer;
    display: flex;
    overflow: hidden;
    position: relative;

    img {
      height: 160%;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.pay-jl-list {
  li {
    margin-top: 8px;
    padding: 12px 8px;
    height: 64px;
    border-bottom: none;

    .name {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      height: 17px;
      color: #788b9d;
      margin-bottom: 4px;
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
        margin: 0 4px;
      }
    }

    .row {
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;
      height: 19px;
      color: #30383f;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        font-family: Akrobat;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 19px;
        height: 19px;
        color: #30383f;
      }

      .right {
        background: rgba(175, 179, 200, 0.2);
        border-radius: 2px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #aeb9c4;
        padding: 4px;

        &.success {
          color: #4ab06a;
          background: rgba(74, 176, 106, 0.2);
        }
      }
    }
  }
}

.help-list {
  margin-top: 20px;

  li {
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #48535e;
  }
}

.user-jv-list {
  ul {
    li {
      border-bottom: 1px solid #f2f2f6;
      padding: 12px 0 8px;
      cursor: pointer;

      span {
        color: #a5a9b3;
        font-size: 12px;
        line-height: 12px;
      }

      p {
        line-height: 20px;
        margin-top: 2px;
        color: #414655;
        font-size: 12px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.bankList-right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 16px 0;

  span {
    cursor: pointer;
    color: var(--color-theme);
  }
}

.card-list {
  li {
    width: 100%;
    height: 96px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 4px 10px 0 rgba(178, 176, 222, 0.4);
    background-image: var(--btn-jb);
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 16px;

    &.usdt {
      background-image: linear-gradient(118deg, #4de2b8, #45ba99 99%);
      box-shadow: 0 4px 10px 0 rgba(72, 200, 164, 0.4);
    }

    &:last-child {
      margin-bottom: 0;
    }

    .sec-img {
      width: 28px;
      height: 28px;
      background-color: #fff;
      border-radius: 50%;
      display: inline-block;
      vertical-align: top;
      flex: none;
      overflow: hidden;
      padding: 6px;
    }

    .sec {
      color: white;
      font-size: 12px;
      margin-left: 8px;

      .sec-text {
        opacity: 0.5;
      }
    }

    .sec-bankNo {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 16px;
      text-align: center;
      color: white;
      font-size: 12px;
    }
  }
}
.user-right-h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .text {
    font-size: 18px;
    color: var(--color-black);
    font-weight: bold;
    line-height: 1;
  }
  .more {
    color: var(--color-gray);
    cursor: pointer;
    font-size: 12px;
    display: flex;
    align-items: center;
    position: relative;
    &:hover {
      text-decoration: underline;
    }
    &::after {
      position: absolute;
      right: -11px;
      content: "\e000\e420\e875\e00A";
      -webkit-font-smoothing: antialiased;
      font-family: ksticon;
      font-style: normal;
      font-weight: 400;
      opacity: 0.3;
    }
  }
}
.user-cark,
.user-jv {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 16px rgba(152, 161, 182, 0.04);
}
.user-jv {
  &:last-child {
    flex: 1;
  }
  &-node {
    min-height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-gray);
    font-size: 12px;
  }
}
.cark-title {
  ul {
    border-radius: 4px;
    display: flex;
    align-items: center;
    background: var(--bg-gray-10);
    padding: 4px;
    li {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      height: 32px;
      cursor: pointer;
      &.hover {
        background: white;
      }
    }
  }
}
</style>
